<template>
    <div>
        <div @click="divlick()">
        <!-- <el-cascader-panel :options="options" :props="{ expandTrigger: 'hover' }"></el-cascader-panel> -->
          <el-input
              v-model="queryInfo.query"
              clearable
              size="small"
              placeholder="输入账号或姓名搜索"
              style="width: 200px;"
              class="filter-item"
              @keyup.enter.native="getTableData()"
          />
          <span>
              <el-button style="margin-left:10px" class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
          </span>
          <span style="margin-left:10px"></span>
          <el-select
            v-model="queryInfo.business_STATUS"
            clearable
            size="small"
            placeholder="业务状态"
            class="filter-item"
            style="width: 120px"
            @change="getTableData()"
          >
            <el-option
              v-for="item in businessOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span style="margin-left:10px"></span>
          <!-- <el-select
            v-model="queryInfo.sales_ID"
            clearable
            size="small"
            placeholder="客户经理"
            class="filter-item"
            style="width: 120px"
            @change="getTableData()"
          >
            <el-option
              v-for="item in salesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-select clearable v-if="this.queryInfo.org_ID === undefined" v-model="queryInfo.query_ORG_ID" :filter-method="userFilter"
          size="small"
          class="filter-item"
          style="width: 220px"
          @change="getTableData()"
          filterable placeholder="机构">
            <el-option
              v-for="item in org_select_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div  class="crdc-opts">
            <span>
              <slot name="left" />
              <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="add()"
              >
                新增
              </el-button>
            </span>
            <!--右侧-->
              <slot name="right" />
            <el-button-group class="crud-opts-right">
                <el-button
                  size="mini"
                  icon="el-icon-refresh"
                  @click="getTableData()"
                />
              </el-button-group>
          </div>
          <el-card>
            <el-table
            :data="tableData"  highlight-current-row
            @current-change="companyCurrentChange"
            @row-contextmenu="rightClick"
            ref="singleTable"
            border stripe
            @sort-change='sortChange'
            style="width: 100%">
            <el-table-column
              fixed
              prop="user_NAME"
              label="账号"
              sortable='custom'
              width="120"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link :underline="false" type="primary">{{scope.row.user_NAME}}</el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="real_NAME"
              label="姓名"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              v-if="nodeUser !== 2"
              prop="org_CONTACT_NAME"
              label="机构全称"
              sortable='custom'
              width="260"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link :underline="false" @click="toManagePage(scope.row.org_ID)" type="primary">{{scope.row.org_CONTACT_NAME}}</el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="user_LOCKED_NAME"
              label="状态"
              sortable='custom'
              :show-overflow-tooltip="true"
              width="90">
            </el-table-column>
            <el-table-column
              prop="create_DATE"
              label="创建日期"
              sortable='custom'
              width="100" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
              prop="active_DATE"
              label="激活日期"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="expire_DATE"
              label="到期日期"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="trial_DATE"
              label="体验到期"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="bind_DEVICE_NAME"
              label="绑定终端"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="dept"
              label="部门"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="position"
              label="职位"
              sortable='custom'
              width="150">
            </el-table-column>
            <el-table-column
              prop="email"
              label="联系邮箱"
              sortable='custom'
              :show-overflow-tooltip="true"
              width="120">
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系电话"
              :show-overflow-tooltip="true"
              sortable='custom'
              width="110">
            </el-table-column>
            <el-table-column
              prop="download_LIMIT"
              label="月下载限数"
              sortable='custom'
              width="110">
            </el-table-column>
            <!-- 数据库中没字段不显示 <el-table-column
              prop="business_END"
              label="近一月过期拒绝登录"
              sortable
              width="160">
            </el-table-column> -->
            <!-- <el-table-column
              label="操作" align="center"
              width="180">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)"  ></el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-sizes="[2, 5, 10, 15]"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle"
          ></el-pagination>
          <el-dialog append-to-body  title="修改客户资料" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
            <el-form ref="form" :rules="rules" class="demo-ruleForm" :model="form"  label-width="180px" >
                <el-form-item label="账号" prop="user_NAME" >
                  <el-col :span="11">
                    <el-input disabled='disabled' v-model="form.user_NAME"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item v-if="isShow" label="初始密码" prop="auth_SECRET" >
                  <el-col :span="11">
                    <el-input v-model="form.auth_SECRET"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="机构" prop="org_ID" >
                  <el-col :span="11">
                    <el-select clearable v-model="form.org_ID" :filter-method="userFilter" filterable placeholder="请选择">
                      <el-option
                        v-for="item in org_select_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <!-- <el-input v-model="form.org_ID"></el-input> -->
                  </el-col>
                </el-form-item>
                <el-form-item label="姓名" prop="real_NAME" >
                  <el-col :span="11">
                    <el-input v-model="form.real_NAME"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="部门" prop="dept" >
                  <el-col :span="11">
                    <el-input v-model="form.dept"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="职位" prop="position">
                  <el-col :span="11">
                    <el-input v-model="form.position"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone" >
                  <el-col :span="11">
                    <el-input v-model="form.phone"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" >
                  <el-col :span="11">
                    <el-input v-model="form.email"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="绑定终端" prop="bind_DEVICE">
                  <el-col :span="11">
                    <el-select  v-model="form.bind_DEVICE" placeholder="请选择">
                      <el-option
                        v-for="item in isOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="到期日" >
                   <el-col :span="11">
                      <el-form-item prop="expire_DATE">
                        <el-date-picker type="date" placeholder="选择日期" v-model="form.expire_DATE"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="体验到期">
                  <el-col :span="11">
                      <el-form-item prop="trial_DATE">
                        <el-date-picker type="date" placeholder="选择日期" v-model="form.trial_DATE"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="月下载限数" prop="download_LIMIT" >
                  <el-col :span="11">
                    <el-input v-model="form.download_LIMIT"></el-input>
                  </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="resetForm('form')" >取消</el-button>
              <el-button v-if="isAdd == true"  type="primary" @click="submitFrom('form')">确认</el-button>
              <el-button v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
            </div>
          </el-dialog>
          <el-dialog append-to-body  title="客户回访" :visible.sync="addDialogVisibleReview" width="35%" @close='resetFormReview'>
            <el-form ref="reviewform" class="demo-ruleForm" :model="reviewform">
              <el-form-item label="客户建议:">
                <el-input type="textarea" :rows="4" v-model="reviewform.proposal"></el-input>
              </el-form-item>
              <el-form-item label="备注说明:">
                <el-input type="textarea" :rows="4" v-model="reviewform.remark"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="resetFormReview()" >取消</el-button>
              <el-button type="primary" @click="submitFromReview('reviewform')">确认</el-button>
            </div>
          </el-dialog>
          <el-dialog append-to-body  title="重置密码" :visible.sync="addDialogVisiblePassword" width="35%" @close='resetFormPassword'>
            <el-form ref="formPassword" class="demo-ruleForm" :rules="rulesPassword" :model="formPassword" label-width="120px">
              <el-form-item label="账号" prop="user_NAME" >
                  <el-col :span="11">
                    <el-input disabled='disabled' v-model="formPassword.user_NAME"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="姓名" prop="real_NAME" >
                  <el-col :span="11">
                    <el-input disabled='disabled' v-model="formPassword.real_NAME"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="初始密码" prop="auth_SECRET" >
                  <el-col :span="11">
                    <el-input v-model="formPassword.auth_SECRET"></el-input>
                  </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="resetFormPassword()" >取消</el-button>
              <el-button type="primary" @click="submitFromPassword('form')">确认</el-button>
            </div>
          </el-dialog>
          </el-card>
        </div>
        <div @mouseleave="leave()" v-show="contextMenuVisible">
          <el-cascader-panel :options="options1"
          v-model="cascaderValue"
          :style="{left:left+'px',top:top+'px'}"
          :props="{ expandTrigger: 'hover' }"
          @change="change"
          class="contextmenu">
          </el-cascader-panel>
        </div>
    </div>
</template>
<script>
export default {
  props: ['nodeUser', 'org_ID'],
  name: 'platformUser',
  created () { //  初始化
    this.getTableData()
    this.querySalesOptions()
    this.queryOrgNodeSelect()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/platform/user', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    async querySalesOptions () {
      const { data: res } = await this.$http.get('query/sales/node')
      this.salesOptions = res
    },
    companyCurrentChange (val) {
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    add () {
      this.form = this.cleanForm
      // this.resetForm()
      // 账号设置 系统分配
      // 初始密码
      // this.queryRoleOptions()
      this.createCode()
      this.form.user_NAME = '系统分配'
      this.form.auth_SECRET = this.code
      this.addDialogVisible = true
      this.isShow = true
      this.isAdd = true
    },
    async handleEdit (index, row) {
      // this.queryRoleOptions()
      this.addDialogVisible = true
      this.isAdd = false
      const { data: res } = await this.$http.get('query/user/by/id', {
        params: { user_ID: row.user_ID }
      })
      this.form = res
      this.form.password = ''
    },
    resetForm (formName) {
      this.cascaderValue = ''
      this.addDialogVisible = false
      this.$refs.form.resetFields()
    },
    async submitFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        this.form.user_NAME = ''
        await this.$http.post('insert/platform/user', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async submitFromOpen (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        await this.$http.post('update/accounts/by/id', this.form)
        this.resetForm()
        this.getTableData()
        this.payVisible = false
      }
    },
    async updateFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        await this.$http.post('update/platform/user', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async deleteFrom (index, row) {
      // await this.$http.post('delete/banner', row)
      this.getTableData()
    },
    async rightClick (row, column, event) {
      event.preventDefault()// 防止默认菜单弹出
      this.$refs.singleTable.setCurrentRow(row)// 设置选中
      this.left = event.clientX
      this.top = event.clientY
      if (row.user_LOCKED === 1) {
        this.options1[3].disabled = true
        this.options1[4].disabled = false
      } else {
        this.options1[3].disabled = false
        this.options1[4].disabled = true
      }
      if (this.queryInfo.org_ID !== undefined) {
        this.left = event.clientX - 200
        this.top = event.y - 80
      }
      // this.left = event.clientX - 200
      // this.top = event.y - 80
      // console.log('left' + this.left + '--top' + this.top)
      this.userForm.user_ID = row.user_ID
      this.reviewform.user_ID = row.user_ID
      this.formPassword.user_ID = row.user_ID
      this.formPassword.user_NAME = row.user_NAME
      this.formPassword.real_NAME = row.real_NAME
      row.org_ID = row.org_ID + ''
      this.form = row
      this.contextMenuVisible = true
    },
    leave () {
      this.cascaderValue = ''
      this.contextMenuVisible = false
    },
    async change (value) {
      if (value[0] === '0') {
        // 客户回访
        this.addDialogVisibleReview = true
      } else if (value[0] === '1') {
        // 修改资料
        this.isShow = false
        this.addDialogVisible = true
      } else if (value[0] === '2') {
        this.createCode()
        this.formPassword.auth_SECRET = this.code
        this.addDialogVisiblePassword = true
        // this.payVisible = true
      } else if (value[0] === '3') {
        this.userForm.user_LOCKED = 1
        // 锁定账号
        await this.$http.post('update/platform/userlocked', this.userForm)
      } else if (value[0] === '4') {
        // 解锁账号
        this.userForm.user_LOCKED = 0
        await this.$http.post('update/platform/userlocked', this.userForm)
      } else if (value[0] === '5') {
        // 临时解绑
        this.userForm.bind_DEVICE = 0
        await this.$http.post('update/platform/binddevice', this.userForm)
      }
      this.contextMenuVisible = false
      this.getTableData()
    },
    divlick () {
      this.contextMenuVisible = false
      this.cascaderValue = ''
    },
    toManagePage (orgId) {
      this.$router.push({ path: '/system/platform/info', query: { selected: orgId } })
    },
    createCode () {
      var code = ''
      // 设置长度，这里看需求，我这里设置了4
      var codeLength = 6
      // 设置随机字符
      var random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      // 循环codeLength 我设置的4就是循环4次
      for (var i = 0; i < codeLength; i++) {
        // 设置随机数范围,这设置为0 ~ 36
        var index = Math.floor(Math.random() * 9)
        // 字符串拼接 将每次随机的字符 进行拼接
        code += random[index]
      }
      // 将拼接好的字符串赋值给展示的code
      this.code = code
    },
    resetFormReview () {
      this.reviewform.proposal = ''
      this.reviewform.remark = ''
      this.addDialogVisibleReview = false
    },
    async submitFromReview () {
      await this.$http.post('insert/user/review', this.reviewform)
      this.addDialogVisibleReview = false
      this.resetFormReview()
    },
    resetFormPassword () {
      // this.formPassword.auth_SECRET = ''
      this.$refs.formPassword.resetFields()
      this.addDialogVisiblePassword = false
    },
    async submitFromPassword () {
      await this.$http.post('update/auth', this.formPassword)
      this.addDialogVisiblePassword = false
      this.resetFormPassword()
    },
    userFilter (query = '') {
      const arr = this.all_org_select_options.filter((item) => {
        return item.label.includes(query) || item.value.includes(query)
      })
      if (arr.length > 50) {
        this.org_select_options = arr.slice(0, 50)
      } else {
        this.org_select_options = arr
      }
    },
    async queryOrgNodeSelect () {
      const { data: res } = await this.$http.get('query/org/node/select')
      this.all_org_select_options = res
      this.userFilter()
    },
    sortChange (column, prop, order) {
      // 可以打印一下该函数的参数就明白了
      // 下面的if判断根据自己的需要些我后台设置的只能识别desc与asc
      if (column.order === 'descending') {
        this.queryInfo.isAsc = 'desc'
      } else if (column.order === 'ascending') {
        this.queryInfo.isAsc = 'asc'
      } else {
        this.queryInfo.isAsc = ''
      }
      // 排序的字段传给后台
      this.queryInfo.orderByColumn = column.prop
      // 传入查询参数，重新查询一次
      this.getTableData()
    }
  },
  data () {
    return {
      form: {
        user_NAME: '',
        auth_SECRET: '',
        org_ID: '',
        real_NAME: '',
        dept: '',
        position: '',
        phone: '',
        email: '',
        bind_DEVICE: 1,
        expire_DATE: null,
        trial_DATE: null,
        download_LIMIT: 0
      },
      cleanForm: {
        user_NAME: '',
        auth_SECRET: '',
        org_ID: '',
        real_NAME: '',
        dept: '',
        position: '',
        phone: '',
        email: '',
        bind_DEVICE: 1,
        expire_DATE: null,
        trial_DATE: null,
        download_LIMIT: 0
      },
      reviewform: {
        proposal: '',
        remark: ''
      },
      userForm: {
      },
      formPassword: {
        auth_SECRET: ''
      },
      queryInfo: {
        query: '',
        business_STATUS: '',
        query_ORG_ID: '',
        sales_ID: '',
        pagenum: 1,
        pagesize: 10,
        org_ID: this.$route.query.selected,
        // desc、asc排序规则
        isAsc: '',
        // 需要排序的字段
        orderByColumn: ''
      },
      totle: 0,
      tableData: [],
      isAdd: '',
      isShow: true,
      addDialogVisible: false,
      payVisible: false,
      salesOptions: [],
      addDialogVisibleReview: false,
      addDialogVisiblePassword: false,
      all_org_select_options: [],
      org_select_options: [],
      code: '',
      isOptions: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      businessOptions: [{
        value: 100,
        label: '全部'
      }, {
        value: 101,
        label: '已锁定'
      }, {
        value: 102,
        label: '未锁定'
      }, {
        value: 1,
        label: '已到期'
      }, {
        value: 2,
        label: '未到期'
      }, {
        value: 3,
        label: '一周内到期'
      }, {
        value: 4,
        label: '一月内到期'
      }],
      roleOptions: [],
      sexOptions: [{
        value: '0',
        label: '女'
      }, {
        value: '1',
        label: '男'
      }],
      value1: ['1', '2'],
      rulesPassword: {
        auth_SECRET: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      rules: {
        auth_SECRET: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        org_ID: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        real_NAME: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        dept: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        position: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      cascaderValue: '',
      contextMenuVisible: false,
      left: '',
      top: '',
      isLock: 'disabled',
      isBind: false,
      options1: [{
        value: '0',
        label: '客户回访',
        disabled: false
      }, {
        value: '1',
        label: '修改资料'
      }, {
        value: '2',
        label: '重置密码',
        divided: false
      }, {
        value: '3',
        label: '锁定账号',
        disabled: false
      }, {
        value: '4',
        label: '解锁账号',
        disabled: false
      }, {
        value: '5',
        label: '临时解绑',
        disabled: false
      }]
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
</style>
